










































import {Vue, Component, Prop} from "vue-property-decorator";

@Component({
    name: "QuestRewards"
})

export default class QuestRewards extends Vue {

    @Prop({default: "0"})
    protected done!: number;

    @Prop({default: "10"})
    protected total!: number;

    @Prop({default: ""})
    protected reward!: number;

  @Prop({default: false})
  protected claimed!: boolean;

  private redeemed = false;

  get progressBarCompleted() {
      const completed = Math.floor((this.done / this.total) * 12);
      if(completed == 12)
          return "w-full rounded rounded-r-full rounded-l-full";
      return "w-"+completed+"/12";
  }

  get progressBarWidth() {
    const completed = Math.floor((this.done / this.total) * 100);
    return completed;
  }

  get progressBarRemaining() {
      const remaining = 12 - Math.floor((this.done / this.total) * 12);
      if(remaining == 12)
          return "w-full rounded rounded-r-full rounded-l-full";
      return "w-"+remaining+"/12";
  }

  get isCompleted() {
      return this.done === this.total;
  }

  get isClaimed() {
    if(this.claimed)
      this.redeemed = true;
    return this.redeemed;
  }

  claimReward() {
    this.redeemed = true;
  }

}
